.responsive {
  display: block;
  width: 300px;
  z-index: -1;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px; 
  position: relative;
  width: 300px;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.25s ease;
}

.overlay_title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 5px;
}

.overlay_blur {
  backdrop-filter: blur(1px);
}

.container:hover .overlay {
  opacity: 1;
}

.coming_soon {
  padding-top: 5px;
}

.certList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

@media only screen and (max-width: 1200px) {
  .certList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .certList {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .certList {
    width: 100%;
  }
  .certItem {
    width: 300px;
    height: 300px;
  }
}