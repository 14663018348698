.home {
  width: 100%;
  align-items: center;
  font-family: "Arial", sans-serif;
  color: #3e497a;
}


.about {
  width: 100%;
  height: calc(70vh - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #21325e;
  color: #f0f0f0;
}

.profile {
  max-width: 100%;
  object-fit: contain;
  height: auto;
  display: block;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px; 
  position: relative;
  width: 200px;
  border-radius: 50%;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 70px;
  color: #e9d35b;
  height: 20px;
}


.about .prompt {
  width: 60%;
  font-size: 24px;
}

.prompt svg {
  font-size: 60px;
  margin: 15px;
  color: white;
}

.skills {
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.skills h1 {
  color: #3e497a;
}

.skills .list {
  list-style: none;
  width: 60%;
}

.list span {
  font-size: 20px;
}


@media (max-height: 900px) {
  .profile {
    padding: 0px;
  }

  .about {
    height: 80vh;	
  }

  .about h2 {
  font-size: 50px;
  color: #e9d35b;
  height: 5px;
}
}

@media only screen and (max-width: 700px) {
  .about h2 {
    font-size: 30px;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .skills {
    text-align: center;
  }
  .list {
    padding: 0;
  }

  .skills h2 {
    font-size: 30px;
  }
}

